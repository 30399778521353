::-webkit-scrollbar {
  width: 6px; /* width of the scrollbar */
}

/* Handle of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #0079C2; /* color of the scrollbar thumb */
  border-radius: 5px; /* roundness of the scrollbar thumb */
}

/* Track of the scrollbar */
::-webkit-scrollbar-track {
  background-color: transparent; /* color of the scrollbar track */
}