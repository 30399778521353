td:nth-of-type(1):before {
  content: "";
  padding-left: 0%;
}
td:nth-of-type(1) {
  padding-left: 0%;
}
@media only screen and (max-width: 800px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  th {
    display: none;
  }
}
