@media only screen and (max-width: 800px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .rawReportTable {
    margin-top: 15px;
  }

  .rawReportTable,
  .rawReportTable > thead,
  .rawReportTable > tbody,
  .rawReportTable th,
  .rawReportTable td,
  .rawReportTable tr {
    display: block;
    border: 0 solid black;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .rawReportTable > thead > tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rawReportTable tr {
    margin: 0 0 1rem 0;
    border: 1px solid black;
    background-color: #eee;
  }

  .rawReportTable td {
    /* Behave like a "row" */
    border: none;
    border-bottom: 1px solid black;
    position: relative;
    height: 20%;
    padding-left: 50%;
  }

  .rawReportTable td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  .rawReportTable td:nth-of-type(1) {
    padding-left: 50%;
  }

  .rawReportTable td:nth-of-type(1):before {
    content: "Code :";
  }

  .rawReportTable td:nth-of-type(2) {
    padding-left: 50%;
  }

  .rawReportTable td:nth-of-type(2):before {
    content: "Unit :";
  }

  .rawReportTable td:nth-of-type(3) {
    padding-left: 50%;
  }

  .rawReportTable td:nth-of-type(3):before {
    content: "Product Name :";
  }

  .rawReportTable td:nth-of-type(4):before {
    content: "Previous Quantity :";
  }
  .rawReportTable td:nth-of-type(5):before {
    content: "Used Quantity :";
  }
  .rawReportTable td:nth-of-type(6):before {
    content: "Used Type :";
  }

  .rawReportTable td:nth-of-type(7):before {
    content: "New Quantity :";
  }
  .rawReportTable td:nth-of-type(8):before {
    content: "For Order :";
  }
  .rawReportTable td:nth-of-type(9):before {
    content: "Dealer Name :";
  }
  .rawReportTable td:nth-of-type(10):before {
    content: "Date :";
  }

  .rawReportTable td:nth-of-type(11):before {
    content: "Entried By :";
  }
  .rawReportTable td:nth-of-type(11) {
    border-bottom: 0px;
  }
}
