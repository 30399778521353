table,
thead,
tbody,
th,
td,
tr {
  border: 1px solid black;
}
#reporttable{
  background-color: #0c1559;
  border-right: 1px solid white;
}
@media only screen and (max-width: 800px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  table{
    margin-top: 15px;

  }
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
    border: 0px solid black;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  tr {
    margin: 0 0 1rem 0;
    border: 1px solid black;
    background-color: #eee;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid black;
    position: relative;
    height: 20%;
    padding-left: 50%;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;

    padding-right: 10px;
    white-space: nowrap;
  }
  td:nth-of-type(1):before {
    content: "ID No.";
  }
  td:nth-of-type(2) {
    padding-left: 0%;
  }
  td:nth-of-type(3) {
    padding-left: 0%;
  }
  td:nth-of-type(4):before {
    content: "Order Receiving Date";
  }
  td:nth-of-type(5):before {
    content: "Expected Delivery Date";
  }
  td:nth-of-type(6):before {
    content: "AWB No.";
  }
  td:nth-of-type(7):before {
    content: "Delivery Partner";
  }
  
}
@media only screen and (min-width: 801px){
    td:nth-of-type(3) {
  min-width: 400px;
}
  }
